import React from 'react';

import NavBg from 'assets/img/nav-bg.png';
import { Link } from 'react-router-dom';

const AboutusHeader = (props) => {
  const { data, title } = props;
  return (
    <>
      <section
        className="page-header position-relative overflow-hidden ptb-60 bg-dark"
        style={{
          background: `url(${NavBg})no-repeat bottom left`,
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-12">
              <div className="text-center">
                <h5 className="fs-3 fw-bold mb-3">{ title ? title : "联系我们"}</h5>
                <nav
                  aria-label="breadcrumb"
                  className="justify-content-center d-flex"
                >
                  {data && (
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item ">
                        <Link to="/" className="text-white nounderline">
                          首页
                        </Link>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to="/contact-us" className="text-white nounderline">
                          联系我们
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item text-white nounderline active"
                        aria-current="page"
                      >
                        {data.title}
                      </li>
                    </ol>
                  )}
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutusHeader;
