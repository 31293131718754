import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import OffCanvasMenu from './OffCanvasMenu';

import LogoWhite from 'assets/img/logo-white.png'
import LogoColor from 'assets/img/logo-color.png'

const Navbar = ({ navDark }) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  useEffect(() => {
    const stickyheader = document.querySelector('.main-header');
    setHeaderTop(stickyheader.offsetTop);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  return (
    <>
      <header
        className={`main-header z-5 ${navDark ? 'position-absolute' : ''} w-100 `}
      >
        <nav
          className={`navbar navbar-expand-xl ${navDark ? 'navbar-dark' : 'navbar-light'
            } sticky-header ${scroll > headerTop ? 'affix' : ''}`}
        >
          <div className="container d-flex align-items-center justify-content-lg-between position-relative">
            <NavLink
              to={process.env.PUBLIC_URL + '/'}
              className="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none"
            >
              <img
                src={LogoWhite}
                alt="logo"
                height={52}
                className="logo-white"
              />
              <img
                src={LogoColor}
                alt="logo"
                height={52}
                className="logo-color"
              />
            </NavLink>

            <NavLink
              className="navbar-toggler position-absolute right-0 border-0"
              to={process.env.PUBLIC_URL + '#offcanvasWithBackdrop'}
              role="button"
            >
              <span
                className="far fa-bars"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasWithBackdrop"
                aria-controls="offcanvasWithBackdrop"
              ></span>
            </NavLink>

            <div className="collapse navbar-collapse justify-content-center">
              <ul className="nav col-12 col-md-auto justify-content-center main-menu">

                <li>
                  <NavLink
                    exact
                    to={process.env.PUBLIC_URL + '/'}
                    className="nav-link"
                  >
                    首页
                  </NavLink>
                </li>


                <li className="nav-item dropdown">
                  <NavLink
                    to={process.env.PUBLIC_URL + '/about-us'}
                    className="nav-link dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    基金会介绍
                  </NavLink>
                  <div className="dropdown-menu border-0 rounded shadow py-0 bg-white" style={{ width: "200px" }}>
                    <div className="dropdown-grid rounded">

                      <div className="dropdown-grid-item">
                        <NavLink to={process.env.PUBLIC_URL + '/about-us'} className="dropdown-link">
                          基金会简介
                        </NavLink>
                        <NavLink to={process.env.PUBLIC_URL + '/about-us/bylaws'} className="dropdown-link">
                          基金会章程
                        </NavLink>
                        {/* <NavLink to={process.env.PUBLIC_URL + '/about-us/namelist'} className="dropdown-link">
                          理事会简介
                        </NavLink> */}
                        <NavLink to={process.env.PUBLIC_URL + '/about-us/organization'} className="dropdown-link">
                          组织架构
                        </NavLink>
                        <NavLink to={process.env.PUBLIC_URL + '/about-us/committee'} className="dropdown-link">
                          学术委员会名单
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <NavLink
                    to={process.env.PUBLIC_URL + '/news'}
                    className="nav-link dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    新闻动态
                  </NavLink>
                  <div className="dropdown-menu border-0 rounded shadow py-0 bg-white" style={{ width: "200px" }}>
                    <div className="dropdown-grid rounded">

                      <div className="dropdown-grid-item">
                        <NavLink to={process.env.PUBLIC_URL + '/news/media'} className="dropdown-link">
                          媒体声音
                        </NavLink>
                        <NavLink to={process.env.PUBLIC_URL + '/news/self'} className="dropdown-link">
                          基金会项目动态
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </li>


                <li className="nav-item dropdown">
                  <NavLink
                    to={process.env.PUBLIC_URL + '/projects'}
                    className="nav-link dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    公益项目介绍
                  </NavLink>
                  <div className="dropdown-menu border-0 rounded shadow py-0 bg-white" style={{ width: "240px" }}>
                    <div className="dropdown-grid rounded">

                      <div className="dropdown-grid-item">
                        <NavLink to={process.env.PUBLIC_URL + '/projects/mainland'} className="dropdown-link">
                          国内活动项目
                        </NavLink>
                        <NavLink to={process.env.PUBLIC_URL + '/projects/hk'} className="dropdown-link">
                          国际及港澳台活动项目
                        </NavLink>
                        <NavLink to={process.env.PUBLIC_URL + '/projects/special'} className="dropdown-link">
                          专项基金项目
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <NavLink
                    to={process.env.PUBLIC_URL + '/policies/cn'}
                    className="nav-link dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    政策法规
                  </NavLink>
                  <div className="dropdown-menu border-0 rounded shadow py-0 bg-white" style={{ width: "200px" }}>
                    <div className="dropdown-grid rounded">

                      <div className="dropdown-grid-item">
                        <NavLink to={process.env.PUBLIC_URL + '/policies/cn'} className="dropdown-link">
                          国家法规
                        </NavLink>
                        <NavLink to={process.env.PUBLIC_URL + '/policies/specification'} className="dropdown-link">
                          基金会规范
                        </NavLink>
                        <NavLink to={process.env.PUBLIC_URL + '/policies/party-buildings'} className="dropdown-link">
                          党建工作
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <NavLink
                    to={process.env.PUBLIC_URL + '/disclosure-infos/report'}
                    className="nav-link dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    信息公示
                  </NavLink>
                  <div className="dropdown-menu border-0 rounded shadow py-0 bg-white" style={{ width: "200px" }}>
                    <div className="dropdown-grid rounded">

                      <div className="dropdown-grid-item">
                        {/* <NavLink to={process.env.PUBLIC_URL + '/disclosure-infos/report'} className="dropdown-link">
                        财务报告
                        </NavLink> */}
                        <NavLink to={process.env.PUBLIC_URL + '/disclosure-infos/qualification'} className="dropdown-link">
                        资质信息
                        </NavLink>
                        <NavLink to={process.env.PUBLIC_URL + '/disclosure-infos/milestone'} className="dropdown-link">
                        项目进展
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </li>


                <li>
                  <NavLink
                    to={process.env.PUBLIC_URL + '/contact-us'}
                    className="nav-link"
                  >
                    联系我们
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to={process.env.PUBLIC_URL + '/partners'}
                    className="nav-link"
                  >
                    合作伙伴
                  </NavLink>
                </li>

              </ul>
            </div>

            <OffCanvasMenu />
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
