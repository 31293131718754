import React from 'react';
import { Link } from 'react-router-dom';

import LogoWhite from 'assets/img/logo-white.png'
import LogoColor from 'assets/img/logo-color.png'


const FooterOne = ({ footerLight, style, footerGradient }) => {
  return (
    <>
      <footer className="footer-section">
        <div
          className={`footer-top ${footerLight ? 'footer-light' : 'bg-dark'} ${
            footerGradient ? 'bg-gradient' : ''
          }  text-white ptb-60`}
          style={style}
        >
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-8 col-lg-4 mb-md-4 mb-lg-0">
                <div className="footer-single-col">
                  <div className="footer-single-col mb-4">
                    <img
                      src={LogoWhite}
                      height={52}
                      alt="logo"
                      className="logo-white"
                    />
                    <img
                      src={LogoColor}
                      alt="logo"
                      height={52}
                      className="logo-color"
                    />
                  </div>
                  <p>
                    深圳亚心慈善基金会推动心血管内科特别是心律失常领域的基础与临床研究、科技成果转化、学术交流合作、疾病科普、健康公益服务等。
                  </p>
                </div>
              </div>
              <div className="col-md-12 col-lg-7 mt-4 mt-md-0 mt-lg-0">
                <div className="row">
                  <div className="col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0">
                    <div className="footer-single-col">
                      <h3>联系我们</h3>
                      <p>
                        深圳市南山区粤海街道滨海社区 <br />
                        海天二路19号盈锋中心1206J
                      </p>
                      <p>
                      联系邮箱：
                        <br />
                        <a href="mailto:szyxfound@163.com">szyxfound@163.com</a>
                      </p>

                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0">
                    <div className="footer-single-col">
                      <h3>相关法规</h3>
                      <ul className="list-unstyled footer-nav-list mb-lg-0">
                        <li>
                          <Link to="/" className="text-decoration-none">
                            基金业规章
                          </Link>
                        </li>
                        <li>
                          <Link to="/" className="text-decoration-none">
                            国务院相关规定
                          </Link>
                        </li>
                        <li>
                          <Link to="/" className="text-decoration-none">
                            十五项章程
                          </Link>
                        </li>
                        <li>
                          <Link to="/" className="text-decoration-none">
                            二十个相关的义务
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0">
                    <div className="footer-single-col">
                      <h3>科研专项</h3>
                      <ul className="list-unstyled footer-nav-list mb-lg-0">
                        <li>
                          <Link to="/" className="text-decoration-none">
                            科研专项资助申请表
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`footer-bottom ${
            footerLight ? 'footer-light' : 'bg-dark'
          } ${footerGradient ? 'bg-gradient' : ''} text-white py-3`}
        >
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-12">
                <div className="copyright-text">
                  <p className="mb-lg-0 mb-md-0 text-center">
                    深圳亚心慈善基金会版权所有©2022{' '}
                    <a
                      style={{ textDecoration: 'none' }}
                      className="text-primary"
                      href="https://beian.miit.gov.cn/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      粤ICP备2022086136号-1
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterOne;
