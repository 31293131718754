import React from 'react';
import Background from 'assets/img/action-bg.png'

const WorkProcessTwo = () => {
  return (
    <>
      <section
        className="work-process ptb-60 bg-dark text-white"
        style={{
          background: `url(${Background}) no-repeat top center`,
          backgroundSize: 'cover',
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col">
              <div
                className="section-heading text-center mb-0"
                data-aos="fade-up"
              >
                <h3 className="pb-3">我们的宗旨</h3>
                <p>
                  遵守宪法、法律、法规和国家政策，践行社会主义核心价值观，遵守社会道德风尚，倡导个人、企业社会责任，致力于心血管
                  <br />
                  疾病防御和普及知识，为社会医疗事业的发展添砖加瓦，促进社会公益事业的发展。
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WorkProcessTwo;
