import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import NewsBig from 'assets/img/news-big-hot.jpg';

import News01 from 'assets/img/news/01.jpeg';
import News02 from 'assets/img/news/02.jpeg';
import News03 from 'assets/img/news/03.jpg';


class NewOne extends Component {
  render() {
    const { darkBg } = this.props;
    return (
      <>
        <section
          className={`customer-review-tab ptb-120 ${
            darkBg ? 'bg-gradient text-white' : 'bg-light'
          } position-relative z-2`}
        >
          <div className="container">
            <div className="row justify-content-center align-content-center pb-3">
              <div className="col">
                <h3 className="title-left-border ps-3">新闻动态</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="tab-pane fade active show" role="tabpanel">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-lg-6 col-md-6">
                      <div className=" mb-5 mb-lg-0 mb-md-0">
                        <img
                          src="assets/img/testimonial/quotes-left.svg"
                          alt="testimonial quote"
                          width="65"
                          className="img-fluid mb-32"
                        />
                        <div className="blockquote-title-review mb-4">
                        <Link className="btn-link text-decoration-none " to='/news/media/detail/211'>
                          <h3 className="mb-0 h4 fw-semi-bold text-primary">
                          规范房颤治疗，守护心脑健康
                          </h3>
                        </Link>
                        </div>
                        <p>
                        由人民日报健康客户端与亚洲心律学会、深圳市亚心慈善基金会共同策划的“国家心血管病中心房颤日特别策划”直播访谈节目，在第10个“中国房颤日”即将到来之际，于2022年6月5日成功举办。
                        </p>
                        <p className="text-muted">2022年6月6日</p>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-6">
                      <div className="author-img-wrap pt-5 ps-5">
                        <div className="testimonial-video-wrapper position-relative">
                          <img
                            src={NewsBig}
                            className="img-fluid rounded-custom shadow-lg"
                            alt="testimonial author"
                          />
                          <div className="position-absolute bg-primary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-lg-4 col-md-6">
                <div className="single-article rounded-custom my-3">
                  <Link to="/news/self/detail/170" className="article-img">
                    <img
                      src={News01}
                      alt="article"
                      className="img-fluid"
                    />
                  </Link>
                  <div className="article-content p-4">
                    <Link to="/news/self/detail/170">
                      <h3 className="h6 text-primary mb-3 article-title limit-2-line-text">
                      为什么看起来健康的人会猝死？心脏突然“咯噔”一下是怎么回事？｜健康大家谈
                      </h3>
                    </Link>
                    <p className="small fw-medium text-muted">2022年01月26日</p>
                    <p className="limit-2-line-text">
                    “心源性猝死”这个词近年来反复出镜，刺激人们的神经，引发了不少人的焦虑，让人扼腕叹息的同时也值得警示。
                    </p>

                    <p className="text-end pt-4">
                      <Link to="/news/self/detail/170" className="text-primary">查看全部</Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-article rounded-custom my-3">
                  <Link to="/news/self/detail/287" className="article-img">
                    <img
                      src={News02}
                      alt="article"
                      className="img-fluid"
                    />
                  </Link>
                  <div className="article-content p-4">
                    <Link to="/news/self/detail/287">
                      <h3 className="h6 text-primary mb-3 article-title limit-2-line-text">
                      “攀登者”训练营-项目介绍
                      </h3>
                    </Link>
                    <p className="small fw-medium text-muted">2022年02月10日</p>
                    <p className="limit-2-line-text">
                    中国医学科学院阜外医院心律失常中心举办“攀登者”训练营计划，首期以提高房颤导管消融技能为目标。
                    </p>

                    <p className="text-end pt-4">
                      <Link to="/news/self/detail/287" className="text-primary">查看全部</Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-article rounded-custom my-3">
                  <Link to="/news/self/detail/232" className="article-img">
                    <img
                      src={News03}
                      alt="article"
                      className="img-fluid"
                    />
                  </Link>
                  <div className="article-content p-4">
                    <Link to="/news/self/detail/232">
                      <h3 className="h6 text-primary mb-3 article-title limit-2-line-text">
                      ICE指导下射频消融教学视频项目启动公告
                      </h3>
                    </Link>
                    <p className="small fw-medium text-muted">2021年12月27日</p>
                    <p className="limit-2-line-text">
                    为促进心血管医生多学科合作，助力青年医生能力提升，由深圳市亚心慈善基金会主办的“ICE指导下房颤射频消融手术系列教学线上展播活动”将于2021年12月31日在北京启动。
                    </p>

                    <p className="text-end pt-4">
                      <Link to="/news/self/detail/232" className="text-primary">查看全部</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default NewOne;
