import React, { Component } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import ScrollToTop from '../components/common/ScrollToTop';
import NotFoundScreen from '../components/others/NotFoundScreen';
import AboutusByLaws from '../pages/about-us/ByLaws';
import AboutusDescrition from '../pages/about-us/Descrition';
import AboutusNameList from '../pages/about-us/NameList';
import CommitteeList from '../pages/about-us/CommitteeList';
import AboutusOrganization from '../pages/about-us/Organization';
import AboutusQualifications from '../pages/about-us/Qualifications';

import NewsMediaDetail from '../pages/news/media/NewsDetail';
import NewsMediaList from '../pages/news/media/NewsList';
import NewsSelfDetail from '../pages/news/self/NewsDetail';
import NewsSelfList from '../pages/news/self/NewsList';



import DisclosureInfosMilestoneDetail from '../pages/disclosure-infos/milestone/DisclosureInfosDetail';
import DisclosureInfosMilestoneList from '../pages/disclosure-infos/milestone/DisclosureInfosList';


import DisclosureInfosRepor from '../pages/disclosure-infos/report';
import DisclosureInfosQualification from '../pages/disclosure-infos/qualification';


import PoliciesCnDetail from '../pages/policies/cn/PoliciesDetail';
import PoliciesCnList from '../pages/policies/cn/PoliciesList';


import PoliciesSpecificationDetail from '../pages/policies/specification/PoliciesDetail';
import PoliciesSpecificationList from '../pages/policies/specification/PoliciesList';


import PoliciesPartyBuildingsDetail from '../pages/policies/party-buildings/PoliciesDetail';
import PoliciesPartyBuildingsList from '../pages/policies/party-buildings/PoliciesList';


import ProjectsSpecialDetail from '../pages/projects/special/ProjectsDetail';
import ProjectsSpecialList from '../pages/projects/special/ProjectsList';

import ProjectsMainlandDetail from '../pages/projects/mainland/ProjectsDetail';
import ProjectsMainlandList from '../pages/projects/mainland/ProjectsList';

import ProjectsHKDetail from '../pages/projects/HK/ProjectsDetail';
import ProjectsHKList from '../pages/projects/HK/ProjectsList';

import TrainingsDetail from '../pages/trainings/TrainingsDetail';
import TrainingsList from '../pages/trainings/TrainingsList';
import ContactUs from '../pages/contact-us/index';
import Partners from '../pages/partners/index';


import Home from '../themes/ahf/index';

class Routes extends Component {
  render() {
    return (
      <>
        <Router>
          <ScrollToTop />
          <Switch>

            {/* good to go */}
            <Route exact path='/' component={Home} />

            <Route exact path='/about-us/descrition' component={AboutusDescrition} />
            {/* <Route exact path='/about-us/namelist' component={AboutusNameList} /> */}
            <Route exact path='/about-us/committee' component={CommitteeList} />
            <Route exact path='/about-us/bylaws' component={AboutusByLaws} />
            <Route exact path='/about-us/organization' component={AboutusOrganization} />
            <Route exact path='/about-us/qualifications' component={AboutusQualifications} />
            <Redirect exact from='/about-us' to='/about-us/descrition' />

            <Route exact path='/trainings/detail/:id' component={TrainingsDetail} />
            <Route exact path='/trainings' component={TrainingsList} />

             <Route exact path='/disclosure-infos/report' component={DisclosureInfosRepor} />

            <Route exact path='/disclosure-infos/qualification' component={DisclosureInfosQualification} />

            <Route exact path='/disclosure-infos/milestone/detail/:id' component={DisclosureInfosMilestoneDetail} />
            <Route exact path='/disclosure-infos/milestone' component={DisclosureInfosMilestoneList} />

            <Redirect exact from='/disclosure-infos' to='/disclosure-infos/report' />


            <Route exact path='/projects/special/detail/:id' component={ProjectsSpecialDetail} />
            <Route exact path='/projects/special' component={ProjectsSpecialList} />

            <Route exact path='/projects/mainland/detail/:id' component={ProjectsMainlandDetail} />
            <Route exact path='/projects/mainland' component={ProjectsMainlandList} />

            <Route exact path='/projects/hk/detail/:id' component={ProjectsHKDetail} />
            <Route exact path='/projects/hk' component={ProjectsHKList} />

            <Redirect exact from='/projects' to='/projects/special' />

            <Route exact path='/news/media/detail/:id' component={NewsMediaDetail} />
            <Route exact path='/news/media' component={NewsMediaList} />

            <Route exact path='/news/self/detail/:id' component={NewsSelfDetail} />
            <Route exact path='/news/self' component={NewsSelfList} />

            <Redirect exact from='/news' to='/news/media' />


            <Route exact path='/policies/cn/detail/:id' component={PoliciesCnDetail} />
            <Route exact path='/policies/cn' component={PoliciesCnList} />

            <Route exact path='/policies/specification/detail/:id' component={PoliciesSpecificationDetail} />
            <Route exact path='/policies/specification' component={PoliciesSpecificationList} />

            <Route exact path='/policies/party-buildings/detail/:id' component={PoliciesPartyBuildingsDetail} />
            <Route exact path='/policies/party-buildings' component={PoliciesPartyBuildingsList} />

            <Redirect exact from='/policies' to='/policies/cn' />

            <Route exact path='/contact-us' component={ContactUs} />

            <Route exact path='/partners' component={Partners} />


            <Route component={NotFoundScreen} />
          </Switch>
        </Router>
      </>
    );
  }
}
export default Routes;
