import React from 'react';
import PageMeta from '../../components/common/PageMeta';
import AboutusHeader from '../../components/about-us/AboutusHeader';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import FooterOne from '../../layout/Footer/FooterOne';
import Background from 'assets/img/action-bg.png'
import { Link } from 'react-router-dom';

const ContactUs = () => {
  return (
    <Layout>
      <PageMeta />
      <Navbar />
      <AboutusHeader title="合作伙伴" />

      <>
        <section className="support-content ptb-60">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-lg-3 col-md-4 d-block d-md-block d-lg-block ">
                <div className="support-article-sidebar sticky-sidebar mb-5 ">
                  <div
                    className="nav flex-column nav-pills w-100 support-article-tab bg-light pt-3 pb-5 px-3 bg-primary rounded bg-heart"
                    id="v-pills-support"
                    aria-orientation="vertical"
                  >
                    <Link
                      className="nav-link text-white active"
                      to={process.env.PUBLIC_URL + '/partners'}
                    >
                      合作伙伴
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-9 col-md-8">

                <div className="support-article-wrap">
                  <h3 className=" mb-4 fw-bold text-center">合作伙伴</h3>

                  <p className="text-muted text-center pt-0 pb-4">
                    <span className="px-3">发布时间：2021年11月12日</span>{' '}
                    <span className="px-3">来源：本站</span>
                  </p>

                  <section
                    className="work-process ptb-20 rounded bg-dark text-white  mb-5"
                    style={{
                      background: `url(${Background}) no-repeat top center`,
                      backgroundSize: 'cover',
                    }}
                  >
                    <p className="p-5 text-center fs-7 lh-lg">
                      遵守宪法、法律、法规和国家政策，践行社会主义核心价值观，遵守社会道德风尚，倡导个人、企业社会责任，致力于心血管
                      <br />
                      疾病防御和普及知识，为社会医疗事业的发展添砖加瓦，促进社会公益事业的发展。
                    </p>
                  </section>

                  <div className="article-detail">
                    <p className="text-center display-6 p-5">请稍候，资料筹备中。</p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
      </>


      <FooterOne footerLight />
    </Layout>
  );
};

export default ContactUs;
