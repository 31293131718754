import React from 'react';
import HeroTitle from '../../components/common/HeroTitle';
import Background from 'assets/img/hero-bg-01.png'
const HeroSeven = () => {
  // Modal Video
  return (
    <>
      <div className="position-relative mb-n5">
      <section
        className="hero-section ptb-120 text-white position-relative"
        style={{
          background: `url(${Background}) no-repeat top center`,
          backgroundSize: 'cover'
        }}
      >
        <div
          className="position-absolute top-0 left-0 right-0 bottom-0"
          style={{
            backgroundImage:
              'linear-gradient(129deg, rgba(89,89,89,0.16) 31%, rgba(45,45,45,0.76) 92%)',
          }}
        ></div>
        <div className="container z-index-1 position-relative">
          <div className="row align-items-center py-5 mb-5">
            <div className="col">
              <div className="hero-content-wrap mt-5 mb-5 text-center">
                <HeroTitle
                  title="践行社会主义核心价值观，遵守社会道德风尚"
                  desc="倡导个人、企业社会责任，致力于心血管疾病防御和普及知识，为社会医疗事业的发展添砖加瓦，<br />促进社会公益事业的发展。"
                />
              </div>
            </div>
          </div>
        </div>


      </section>


        <svg className='position-absolute bottom-0'  viewBox="0 0 1440 170" version="1.1" >
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Group" fill-rule="nonzero">
                    <path d="M0,133.718211 L60,101.718211 C120,69.7182109 240,5.71821094 360,0.418210943 C480,-5.28178906 600,48.7182109 720,75.0182109 C840,101.718211 960,101.718211 1080,96.4182109 C1200,90.7182109 1320,80.7182109 1380,75.0182109 L1440,69.7182109 L1440,165.718211 L1380,165.718211 C1320,165.718211 1200,165.718211 1080,165.718211 C960,165.718211 840,165.718211 720,165.718211 C600,165.718211 480,165.718211 360,165.718211 C240,165.718211 120,165.718211 60,165.718211 L0,165.718211 L0,133.718211 Z" id="Path" fill="#57AECF"></path>
                    <path d="M0,147.718211 L60,115.718211 C120,83.7182109 240,19.7182109 360,14.4182109 C480,8.71821094 600,62.7182109 720,89.0182109 C840,115.718211 960,115.718211 1080,110.418211 C1200,104.718211 1320,94.7182109 1380,89.0182109 L1440,83.7182109 L1440,179.718211 L1380,179.718211 C1320,179.718211 1200,179.718211 1080,179.718211 C960,179.718211 840,179.718211 720,179.718211 C600,179.718211 480,179.718211 360,179.718211 C240,179.718211 120,179.718211 60,179.718211 L0,179.718211 L0,147.718211 Z" id="Path" fill="#FFFFFF"></path>
                </g>
            </g>
        </svg>

      </div>
    </>
  );
};

export default HeroSeven;
