import React from 'react';
import PageMeta from '../../components/common/PageMeta';
import AboutusHeader from '../../components/about-us/AboutusHeader';
import AboutusDetail from '../../components/about-us/AboutusDetail';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import FooterOne from '../../layout/Footer/FooterOne';
import Background from 'assets/img/action-bg.png';

const CommitteeList = () => {
  return (
    <Layout>
      <PageMeta />
      <Navbar />
      <AboutusHeader  title="基金会学术委员会名单" />
      <AboutusDetail>
        <div className="support-article-wrap">
          <h3 className=" mb-4 fw-bold text-center">基金会学术委员会名单</h3>

          <p className="text-muted text-center pt-0 pb-4">
            <span className="px-3">发布时间：2021年11月12日</span>
            <span className="px-3">来源：本站</span>
          </p>

          <section
            className="work-process ptb-20 rounded bg-dark text-white  mb-5"
            style={{
              background: `url(${Background}) no-repeat top center`,
              backgroundSize: 'cover',
            }}
          >
            <p className="p-5 text-center fs-7  lh-lg">
              遵守宪法、法律、法规和国家政策，践行社会主义核心价值观，遵守社会道德风尚，倡导个人、企业社会责任，致力于心血管
              <br />
              疾病防御和普及知识，为社会医疗事业的发展添砖加瓦，促进社会公益事业的发展。
            </p>
          </section>

          <div className="article-detail">
            <p>
              <strong className="fs-4 me-2 text-primary">北京</strong>
              姚焰、刘书旺、吴永全、陈学智、程康安、胡继强、梁卓、田颖、吴灵敏、张萍、郑黎晖、郭豫涛</p>

            <p>
              <strong className="fs-4 me-2 text-primary">上海</strong>
              浦介麟、臧敏华、陈岗、胡锋、沈利水、朱文青
            </p>

            <p>
              <strong className="fs-4 me-2 text-primary">天津</strong>
              陈炳伟、蔡衡、马薇
            </p>

            <p>
              <strong className="fs-4 me-2 text-primary">河北</strong>
              刘刚、谢瑞芹
            </p>

            <p>
              <strong className="fs-4 me-2 text-primary">辽宁</strong>
              张树龙、杨桂棠、于波
            </p>

            <p>
              <strong className="fs-4 me-2 text-primary">黑龙江</strong>
              梁兆光、李述峰、潘震华、王召军
            </p>

            <p>
              <strong className="fs-4 me-2 text-primary">江苏</strong>
              王如兴、惠杰
            </p>


            <p>
              <strong className="fs-4 me-2 text-primary">浙江</strong>
              林加锋、蒋晨阳、储慧民
            </p>

            <p>
              <strong className="fs-4 me-2 text-primary">山东</strong>
              张春盛、任满意、钟敬泉
            </p>

            <p>
              <strong className="fs-4 me-2 text-primary">河南</strong>
              王现青、王红雷、赵永辉
            </p>

            <p>
              <strong className="fs-4 me-2 text-primary">湖北</strong>
              张劲林、王炎
            </p>

            <p>
              <strong className="fs-4 me-2 text-primary">湖南</strong>
              石瑞正、刘启明、张志辉
            </p>

            <p>
              <strong className="fs-4 me-2 text-primary">广东</strong>
              何建桂、陈秋雄、杨平珍、陈文生、杜忠鹏、李晋新、王月刚
            </p>


            {/* <p>
              <strong className="fs-4 me-2 text-primary">广西</strong>
            </p> */}

            {/* <p>
              <strong className="fs-4 me-2 text-primary">重庆</strong>
            </p> */}

            <p>
              <strong className="fs-4 me-2 text-primary">四川</strong>
              胡宏德、付华
            </p>

            <p>
              <strong className="fs-4 me-2 text-primary">陕西</strong>
              孙超峰、李国良
            </p>

            {/* <p>
              <strong className="fs-4 me-2 text-primary">安徽</strong>
            </p> */}

            <p>
              <strong className="fs-4 me-2 text-primary">山西</strong>
              王睿、高兵兵、梁斌、王海雄
            </p>

            {/* <p>
              <strong className="fs-4 me-2 text-primary">内蒙</strong>
            </p> */}

            {/* <p>
              <strong className="fs-4 me-2 text-primary">吉林</strong>
            </p> */}

            <p>
              <strong className="fs-4 me-2 text-primary">福建</strong>
              黄卫斌、侯炳波
            </p>

            <p>
              <strong className="fs-4 me-2 text-primary">江西</strong>
              洪葵
            </p>

            {/* <p>
              <strong className="fs-4 me-2 text-primary">海南</strong>
            </p> */}

            <p>
              <strong className="fs-4 me-2 text-primary">贵州</strong>
              杨龙
            </p>

            <p>
              <strong className="fs-4 me-2 text-primary">云南</strong>
              郭金锐、李绍龙、陶四明
            </p>


            <p>
              <strong className="fs-4 me-2 text-primary">安徽</strong>
              徐健、喻荣辉
            </p>

            <p>
              <strong className="fs-4 me-2 text-primary">重庆</strong>
              殷跃辉
            </p>


            {/* <p>
              <strong className="fs-4 me-2 text-primary">青海</strong>
            </p> */}


            <p>
              <strong className="fs-4 me-2 text-primary">新疆</strong>
              姜述斌、周贤惠
            </p>

            {/* <p>
              <strong className="fs-4 me-2 text-primary">宁夏</strong>
            </p> */}

          </div>
        </div>
      </AboutusDetail>
      <FooterOne footerLight />
    </Layout>
  );
};

export default CommitteeList;
