import React , { useEffect , useState } from 'react';
import PageMeta from '../../../components/common/PageMeta';

import Banner from '../../../components/projects/Banner';
import DetailWrap from '../../../components/projects/Detail';


import Navbar from '../../../layout/Header/Navbar';
import Layout from '../../../layout/Layout';
import FooterOne from '../../../layout/Footer/FooterOne';
import { Link, useHistory } from 'react-router-dom';

import BlogPagination from 'components/pagination/BlogPagination';
import useAxios from 'axios-hooks';

import classNames from 'classnames'

import ReactPaginate from 'react-paginate';

import Background from 'assets/img/action-bg.png'


const NewsList = () => {

  const [currentPage, setCurrentPage] = useState(1)

  const [{ data, loading, error }, fetchTableData] = useAxios(
    `/api/articles?sort=-published_at&page[size]=11&filter[categories]=11&page[number]=${currentPage}`
  )

  const history = useHistory()


  useEffect(() => {

  }, [])


  const onPageChange = (params) => {

    let search = params.selected === 0 ? '' : '?page=' + ( params.selected + 1 );

    if ( currentPage !== params.selected + 1 ) {
      setCurrentPage( params.selected + 1 )
        window.scrollTo(0, 0);
        history.push({
          search: search
        });
    }

  }

  return (
    <Layout>
      <PageMeta />
      <Navbar />
      <Banner />
      <DetailWrap>
        <div className="support-article-wrap">
          <ul className="support-article-list list-unstyled mt-n4">
            {data &&
                data?.data.map((item, index) => (
                    <li className={classNames({
                      'pb-4' : index === 0,
                      'py-4' : index !== 0
                    })} key={index}>
                      <div className="row align-items-center">
                        <div className="col-lg-6 col-xl-4 mb-3 mb-lg-0">
                          <img
                              src={item.featured_image?.original_url}

                              className="img-fluid"
                              alt=""
                          />
                        </div>
                        <div className="col-lg-6 col-xl-8">
                          <Link
                              to={process.env.PUBLIC_URL + '/projects/hk/detail/' + item.id}
                              className="text-decoration-none d-block text-muted"
                          >
                            <h3 className="h5 support-article-title text-primary">
                              {item.title}
                            </h3>
                            <p className="text-muted">
                              <span className="pe-3">发布时间：{item.published_at}</span>
                              <span className="pe-3">来源：本站</span>
                            </p>
                            <p>
                              {item.extract}
                            </p>
                            <span className="btn-link text-decoration-none text-primary read-more-link">
                          查看详情 <i className="far fa-arrow-right ms-2"></i>
                        </span>
                          </Link>
                        </div>
                      </div>
                    </li>
                ))}
          </ul>

          {!loading && data?.meta?.last_page > 1 && <div className="pagination-wrap py-3 justify-content-end ">
            <ReactPaginate
                pageCount={ data.meta.last_page }
                forcePage={ data.meta.current_page - 1 }
                marginPagesDisplayed={2}
                pageRangeDisplayed={1}
                onPageChange={ onPageChange }
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousLinkClassName="page-link"
                nextLinkClassName="page-link"
                containerClassName="pagination article-pagination"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link page-break-link"
                previousLabel="前一页"
                nextLabel="后一页"
                activeClassName="active"
                previousClassName="page-item prev-page-item"
                nextClassName="page-item next-page-item"
            />
          </div>}

        </div>
      </DetailWrap>
      <FooterOne footerLight />
    </Layout>
  );
};

export default NewsList;
