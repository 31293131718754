import React from 'react';
import PageMeta from '../../components/common/PageMeta';
import AboutusHeader from '../../components/about-us/AboutusHeader';
import AboutusDetail from '../../components/about-us/AboutusDetail';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import FooterOne from '../../layout/Footer/FooterOne';
import Background from 'assets/img/action-bg.png'
import ByLawsImg1 from 'assets/img/by-laws-1.jpg'
import ByLawsImg2 from 'assets/img/by-laws-2.png'
import ByLawsImg3 from 'assets/img/by-laws-3.png'


const ByLaws = () => {
  return (
    <Layout>
      <PageMeta />
      <Navbar />
      <AboutusHeader title="基金会章程" />
      <AboutusDetail>
        <div className="support-article-wrap">
          <h3 className=" mb-4 fw-bold text-center">基金会章程</h3>

          <p className="text-muted text-center pt-0 pb-4">
            <span className="px-3">发布时间：2021年11月12日</span>{' '}
            <span className="px-3">来源：本站</span>
          </p>

          <section
            className="work-process ptb-20 rounded bg-dark text-white  mb-5"
            style={{
              background: `url(${Background}) no-repeat top center`,
              backgroundSize: 'cover',
            }}
          >
            <p className="p-5 text-center fs-7 lh-lg">
              遵守宪法、法律、法规和国家政策，践行社会主义核心价值观，遵守社会道德风尚，倡导个人、企业社会责任，致力于心血管
              <br />
              疾病防御和普及知识，为社会医疗事业的发展添砖加瓦，促进社会公益事业的发展。
            </p>
          </section>

          <div className="article-detail">
            <p>
              <img src={ByLawsImg1} alt="" />
              <img src={ByLawsImg2} alt="" />
              <img src={ByLawsImg3} alt="" />
            </p>
          </div>
        </div>
      </AboutusDetail>
      <FooterOne footerLight />
    </Layout>
  );
};

export default ByLaws;
