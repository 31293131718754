import React from 'react';
import SectionTitle from '../common/SectionTitle';
import useHover from 'hooks/use-hover';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';

const FeaTureItem = ({ link, children }) => {

  const history = useHistory()

  const [hoverRef, isHovered] = useHover();

  return (
    <div data-aos="fade-up" data-aos-delay="50" ref={hoverRef} className={
      classnames({
        'position-relative': true,
        'active': isHovered,
        'cursor-pointer': isHovered
      })}
      onClick={() => {
        history.push(link)
      }}
    >

      {children}

    </div>
  );
};

const FeatureFour = () => {
  return (
    <>
      <section className="feature-section ptb-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-12">
              <SectionTitle
                title="基金会介绍"
                description="经深圳市民政局批准，基金会于 2021 年 4 月 13 日正式成立，<br />开展各种形式的募捐活动，接受政府资助，接受自然人、法人及其他组织的合法捐赠。"
                centerAlign
              />
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-6">
              <FeaTureItem link="/projects/mainland">
                <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                  <div className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                    <i className="fad fa-shield-alt text-primary fa-2x"></i>
                  </div>
                  <h3 className="h5">学术培训</h3>
                  {/* <p className="text-muted">2021.08</p>
    <p className='mb-0'>
    大多数先心病患者经过手术治疗，都可以得到康复。然而，我国每年完成的先天性心脏病外科手术仅在7万例左右，有近半数患者未接受治疗。
    </p> */}
                </div>

                <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                  <img src="assets/img/shape/dot-big-square.svg" alt="shape" />
                </div>
              </FeaTureItem>
            </div>
            <div className="col-lg-4 col-md-6">

              <FeaTureItem link="/disclosure-infos">
                <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0">
                  <div className="feature-icon d-inline-block bg-danger-soft rounded-circle mb-32">
                    <i className="fad fa-comment-alt-smile text-danger fa-2x"></i>
                  </div>
                  <h3 className="h5">信息公示</h3>
                  {/* <p className="text-muted">2021.08</p>
                  <p className='mb-0'>
                  大多数先心病患者经过手术治疗，都可以得到康复。然而，我国每年完成的先天性心脏病外科手术仅在7万例左右，有近半数患者未接受治疗。
                  </p> */}
                </div>
              </FeaTureItem>


            </div>
            <div className="col-lg-4 col-md-6">

              <FeaTureItem link="/policies/specification">
                <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0">
                  <div className="feature-icon d-inline-block bg-success-soft rounded-circle mb-32">
                    <i className="fad fa-grin-stars text-success fa-2x"></i>
                  </div>
                  <h3 className="h5">政策法规</h3>
                  {/* <p className="text-muted">2021.08</p>
                  <p className='mb-0'>
                  大多数先心病患者经过手术治疗，都可以得到康复。然而，我国每年完成的先天性心脏病外科手术仅在7万例左右，有近半数患者未接受治疗。
                  </p> */}
                </div>

                <div className="dot-shape-bg position-absolute z--1 right--40 bottom--40">
                  <img src="assets/img/shape/dot-big-square.svg" alt="shape" />
                </div>
              </FeaTureItem>

            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureFour;
