import React from 'react';
import PageMeta from '../../components/common/PageMeta';
import FeatureFour from '../../components/features/FeatureFour';
import NewOne from '../../components/news/NewOne';
import WorkProcessTwo from '../../components/work-process/WorkProcessTwo';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import HeroSeven from './HeroSeven';

const HomeDataAnalysis = () => {
  return (
    <Layout>
      <PageMeta title='首页 - 深圳亚心慈善基金会' />
      <Navbar navDark />
      <HeroSeven />
      <FeatureFour />
      <WorkProcessTwo />
      <NewOne />
      <FooterOne footerLight />
    </Layout>
  );
};

export default HomeDataAnalysis;
